<template>
	<div class="navbar">
		<div class="inside">
			<div class="logo-hold">
				<img src="/public-static/logos/ace-digital-logo.png" alt="logo" class="logo">
			</div>
			<div class="nav-hold">
				<span class="navbtn" :class="{active : activeitem === 'sites'}" @click="$emit('navchange', 'sites')">Sites</span>
				<span class="navbtn" :class="{active : activeitem === 'servers'}" @click="$emit('navchange', 'servers')">Servers</span>
			</div>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"

export default {
	name: 'NavBar',
	components: {

	},
	props: {
		activeitem: String,
	},
	mixins: [globalMixin],
	data() {
		return {
			store: store,

		}
	},
	computed: {

	},
	methods: {},
	watch: {},
	mounted() {

	},
	created() {

	},
}
</script>

<style scoped>
.navbar {
	height: 70px;
	width: 100%;
	background-color: var(--bluelight);
	position: relative;
}
.navbar .inside {
	margin: 0 auto;
	height: 100%;
	padding-left: 50px;
	padding-right: 50px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.logo {
	height: 30px;
}
.navbtn {
	background-color: transparent;
	color: #FFF;
	padding: 10px 20px;
	border-radius: 10px;
	cursor: pointer;
	transition: all 0.3s linear;
	font-weight: 500;
	font-size: 16px;
	margin: 0 2px;
}
.navbtn:hover, .navbtn.active {
	background-color: var(--bluedark);
}
</style>
