<template>
	<div class="sites-list">
		<div class="inner">
			<ALTable :searchbox="false" :rowdata="rowData" tblclasses="noborderv"
				:cols="[
					{title: 'Name', fldid:'label', sortable: true},
					{title: 'Host', fldid:'location', sortable: true},
					{title: 'Apps/Sites', fldid:'numapps'},
					{title: 'IP', fldid:'public_ip'},
					{title: 'Last Fetch', fldid:'lastfetchtimenice'},
				]"
			>
			</ALTable>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import ALTable from "@/components/ALTable.vue";

export default {
	name: 'ServersContent',
	components: {
		ALTable

	},
	mixins: [globalMixin],
	data() {
		return {
			store: store,

			hasMounted: false,
		}
	},
	computed: {
		rowData() {
			if(this.hasMounted === false) return []
			let rows = []
			for(let a of this.store.servers) {
				if(a.deleted === 0 && a.exclude === 0) {
					rows.push(a)
				}
			}
			return rows
		},
	},
	methods: {

	},
	watch: {

	},
	props: {

	},
	mounted() {
		this.hasMounted = true
	},
	created() {
		document.title = "Servers - ACE Digital"
	},
}
</script>

<style scoped>
.inner {
	padding: 50px;
}
</style>
