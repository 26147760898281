import { reactive } from 'vue'

let isDevServer = false;
let urlroot = 'https://console.ace.digital/' //api call (main) url
if (window.location.origin.indexOf('localhost') > -1 || window.location.origin.indexOf('.test') > -1) {
	isDevServer = true
	urlroot = 'https://ace-console-2024.test/'
}

export const store = reactive({
	kalert: {
		shown: false,
		text: "",
		type: 'danger',
	},
	isDevServer: isDevServer,
	urlroot: urlroot,
	apps: [],
	servers: [],
	plugins: [],
	users: [],
	mouseClickInFilter: false,
	mouseClickAnywhere: false,

	//exceljs export formats
	// xlfmt: {
	// 	header: {font: {bold: true, name: 'Tahoma', size: 10}},
	// 	boldborder: {
	// 		font: {bold: true, name: 'Tahoma', size: 10},
	// 		border: xlborder
	// 	},
	// 	textborder: {
	// 		font: {name: 'Tahoma', size: 10},
	// 		border: xlborder
	// 	},
	// 	numberborder: {
	// 		font: {name: 'Tahoma', size: 10},
	// 		// numFmt: '#',
	// 		border: xlborder,
	// 	},
	// 	borderthead: {
	// 		font: {bold: true, name: 'Tahoma', size: 10},
	// 		border: xlborder,
	// 		fill: {type: 'pattern', pattern: 'solid', fgColor: {argb: 'FFEAEAEA'}}
	// 	},
	// },

})