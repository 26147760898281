import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import App from './App.vue'
import VueApexCharts from "vue3-apexcharts";
//import vue3GoogleLogin from 'vue3-google-login'
// import Exceljs from "exceljs";

import LoginPage from "@/components/pages/LoginPage";
import HomePage from "@/components/pages/HomePage";

import './style.css'

//routing
const routes = [
	{path: '/login', component: LoginPage},
	{path: '/', component: HomePage},
]

const router = createRouter({
	hashbang: false,
	routes: routes,
	history: createWebHistory(),
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition
		}
		return {x: 0, y: 0};
	},
});

//initialise app
const app = createApp(App)
app.use(router);
app.use(VueApexCharts);
app.mount('#app')
