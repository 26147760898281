<template>
	<div id="app" @click="catchMouseClickAnywhere">
		<AlertTop v-if="store.kalert.shown" v-bind:obj="store.kalert"></AlertTop>

		<router-view></router-view>

	</div>
</template>

<script>
import AlertTop from "@/components/AlertTop";
// import axios from "axios";
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
export default {
	name: 'App',
	components: {
		AlertTop
	},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,
			requestedRoute: '',
		}
	},
	computed: {

	},
	methods: {
		catchMouseClickAnywhere() {
			if(this.store.mouseClickAnywhere === false) {
				this.store.mouseClickAnywhere = true
				setTimeout(function () {
					this.store.mouseClickAnywhere = false
				}.bind(this), 50)
			}
		},
	},
	mounted() {
		this.requestedRoute = this.$router.currentRoute.path
	},
	created() {
		//handle incoming GET parameters - there might be 'email' and 'token' vals passed
		this.store.getparams = this.$route.query
	},
	watch: {
		$route() {
			//force close alert on page change
			this.closeKalert()
		},
	},
}
</script>

<style>
#app {

}
</style>


