<template>
	<div class="blanket">
		<div class="wl-popup" :style="widthstr">
			<div class="popup-tbar" :class="tabsclass">
				<h2>{{title}}</h2>
				<span @click="$emit('close')" class="wl-popup-exit"><i class="mdi mdi-close"></i></span>
			</div>
			<div v-if="hastabs === true" class="wl-popoup-tabs-section">
				<slot name="tabs"></slot>
			</div>
			<div class="wl-popup-content">
				<slot name="main"></slot>
			</div>
			<div class="wl-popup-buttons-section" v-if="!nobuttons">
				<slot name="buttons"></slot>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "BlanketOverlayWithPopup",
	components: {},
	props: {
		title: String,
		width: String,
		hastabs: Boolean,
		nobuttons: Boolean,
	},
	data: function () {
		return {}
	},
	computed: {
		widthstr() {
			if(this.width) {
				if(this.width.indexOf("px") > 0 || this.width.indexOf("%") > 0) {
					return 'width: '+this.width+';';
				}
				else return 'width: '+parseInt(this.width)+'px;';
			}
			return 'width: 500px;'; //default
		},
		tabsclass() {
			if(this.hastabs && this.hastabs === true) {
				return 'topTabsExist'
			}
			return ''
		},
	},
	methods: {},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>
.blanket {
	height: 100%;
	width: 100%;
	background-color: rgba(0, 0, 0, 0.4);
	position: fixed;
	z-index: 1000;
	top: 0;
	left: 0;
	overflow-y: auto;
	opacity: 1;
}
.wl-popup {
	background-color: #FFF;
	margin: 55px auto;
	text-align: left;
	position: relative;
}
.popup-tbar {
	text-align: left;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: var(--bluelight);
}
.popup-tbar.topTabsExist {

}
.popup-tbar h2 {
	padding: 20px 30px;
	margin-block-start: 0;
	margin-block-end: 0;
	font-size: 18px;
	font-weight: bold;
	line-height: 1.2;
	color: #FFF;
}
.wl-popup .wl-popup-exit {
	font-weight: normal;
	margin-right: 25px;
	font-size: 25px;
	color: #FFF;
	cursor: pointer;
	transition: all 0.3s ease-in-out;
}
.wl-popup-exit:hover {
	color: #DDD;
}
.wl-popoup-tabs-section {
	background-color: #F1F2F6;
	padding: 10px 30px 0;
}
.wl-popup-content {
	padding: 10px 30px;
}
.wl-popup-buttons-section {
	background-color: #F1F2F6;
	border-top: 1px solid #DDD;
	margin-top: 10px;
	padding: 10px 30px 10px;
}
</style>