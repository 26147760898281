<template>
	<div class="bgpage" style="min-height: 100vh;">
		<NavBar :activeitem="activeview" @navchange="activeview = $event"></NavBar>

		<SitesContent v-if="activeview === 'sites'"></SitesContent>
		<ServersContent v-if="activeview === 'servers'"></ServersContent>

	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import axios from "axios";
import NavBar from "@/components/NavBar.vue";
import SitesContent from "@/components/SitesContent.vue";
import ServersContent from "@/components/ServersContent.vue";
export default {
	name: 'HomePage',
	components: {
		ServersContent,
		NavBar,
		SitesContent,
	},
	mixins: [globalMixin],
	data() {
		return {
			store: store,

			activeview: 'sites',

		}
	},
	computed: {

	},
	methods: {
		getInitialData() {
			let self = this
			axios.post(
				"post/get-initial-data.php",
				JSON.stringify({})
			).then(function(response) {
				let ret = response.data //response.data is returned info
				if(!ret.loggedIn || ret.loggedIn !== true) {
					self.$router.push("/login")
				}
				else if(ret.loggedIn === true) {
					if(ret.apps) self.store.apps = ret.apps
					if(ret.servers) self.store.servers = ret.servers
					if(ret.plugins) self.store.plugins = ret.plugins
					if(ret.users) self.store.users = ret.users
				}
			}).catch(function(error) {
				console.log(error);
			});
		},
	},
	watch: {

	},
	props: {

	},
	mounted() {
		this.getInitialData()
	},
	created() {

	},
}
</script>

<style scoped>

</style>
