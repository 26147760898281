
// import axios from "axios";

export const globalMixin = {
	methods: {
		numdisplay: function (num) {
			if(num == 0) return 0
			if(!num) return null
			return num.toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 0});
		},
		numdisplayNdp: function (num, numberOfDecplc) {
			if(num == 0) return 0
			if(!num) return null
			return num.toLocaleString('en-US', {minimumFractionDigits: numberOfDecplc, maximumFractionDigits: numberOfDecplc});
		},
		flround(num, nDecimals = 0) {
			if(num == 0) return 0
			if(!num) return null
			if(isNaN(num)) {
				num = parseFloat(num.replace(/,/g, ''))
			}
			num = parseFloat(num)
			try {
				return parseFloat(num.toFixed(nDecimals))
			} catch (e) {
				return num
			}
		},
		numdisplayKeepExisting: function(num, nDecimals = null) {
			if(!num && num !== 0) return null
			if(this.store.viewPreferences.commasInData === true) {
				if(nDecimals !== null) {
					return num.toLocaleString('en-US', {minimumFractionDigits: nDecimals, maximumFractionDigits: nDecimals});
				}
				return num.toLocaleString('en-US');
			}
			else if(num && !isNaN(num) && nDecimals !== null) { //no commas but need decimals
				return num.toFixed(nDecimals)
			}
			return num
		},
		numOrNull(val) {
			if(!val || val && val.length === 0) val = 0
			val = parseFloat(val)
			if(val === 0) return null
			return val
		},
		numOrZero(val) {
			if(!val || val && val.length === 0) val = 0
			val = parseFloat(val)
			if(val === 0) return 0
			return val
		},
		ucfirst: function (str) {
			if(!str) return ''
			if(str.length === 0) return str;
			return str[0].toUpperCase() + str.substring(1);
		},
		intToYes: function (str) {
			if (str) {
				if (parseInt(str) === 1) return "Yes";
			}
			return '';
		},
		boolToYes(bool) {
			if (bool && bool === true) return "Yes";
			return '';
		},
		zeroToBlank(str) {
			if (parseInt(str) === 0) {
				return '';
			}
			return str;
		},
		niceDateTimeFromUnix(unix_timestamp, includeTime) {
			let a = new Date(unix_timestamp * 1000);
			let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
			let year = a.getFullYear();
			let month = months[a.getMonth()];
			let date = a.getDate();

			let timestr = "";
			if (includeTime === true) {
				let hour = a.getHours();
				let min = a.getMinutes();
				if (min < 10) min = "0" + min;
				// let sec = a.getSeconds();
				timestr = ' ' + hour + ':' + min; // + ':' + sec;
			}
			return date + ' ' + month + ' ' + year + timestr;
		},
		niceMonthFromInt(int) {
			let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
			int = parseInt(int)
			return months[int - 1];
		},
		ntobr(str) {
			return str.replaceAll("\n", "<br/>");
		},
		areArraysIdentical(arrA, arrB) {
			if (arrA === arrB) return true;
			if (arrA == null || arrB == null) return false;
			if (arrA.length !== arrB.length) return false;
			arrA.sort();
			arrB.sort();
			// If you don't care about the order of the elements inside the array, you should sort both arrays here.
			// Please note that calling sort on an array will modify that array. You might want to clone your array first.
			for (let i = 0; i < arrA.length; ++i) {
				if (arrA[i] !== arrB[i]) return false;
			}
			return true;
		},
		stripTagsFromString(str) {
			return str.replace(/(<([^>]+)>)/gi, "");
		},
		// clearLocalStorage() {
		// 	window.localStorage.removeItem('useremail');
		// 	window.localStorage.removeItem('usertoken');
		// 	window.localStorage.removeItem('userid');
		// },
		joinObjectIds(obs, keyname = 'id', sep = '|') {
			let arr = this.getObIdsToArr(obs, keyname)
			return arr.join(sep)
		},
		getObIdsToArr(obs, keyname = 'id') {
			let arr = []
			for(let ob of obs) {
				arr.push(ob[keyname])
			}
			return arr //an array of integers/strings
		},
		getObjectsFromArrayById(inobarr, idarr) {
			let arr = []
			for(let id of idarr) {
				let newob = inobarr.find(item => item.id === id)
				if(newob) arr.push(newob)
			}
			return arr
		},
		splitpipe(valstr) {
			let ret = [];
			if (valstr !== "") {
				if (isNaN(valstr)) {
					if (valstr.indexOf("|") > 0) {
						let temp = valstr.split("|");
						for (let i = 0; i < temp.length; i++) {
							let ti = parseInt(temp[i]);
							ret.push(ti);
						}
					}
					else {
						ret.push(parseInt(valstr));
					}
				}
				else {
					ret.push(valstr);
				}
			}
			return ret;
		},
		cleanMSWord(text) {
			let s = text;
			s = s.replace(/[\u2018|\u2019|\u201A]/g, "'"); // smart single quotes and apostrophe
			s = s.replace(/[\u201C|\u201D|\u201E]/g, "\""); // smart double quotes
			s = s.replace(/\u2026/g, "..."); // ellipsis
			s = s.replace(/[\u2013|\u2014]/g, "-"); // dashes
			s = s.replace(/\u02C6/g, "^"); // circumflex
			s = s.replace(/\u2039/g, ""); // open angle bracket
			s = s.replace(/[\u02DC|\u00A0]/g, " "); // spaces
			return s;
		},
		
		//tables stuff
		tblPrepareFilteredRows(rows, filterText = '', sortColName = null, sortColOrder = 'desc', forceKeepIds = []) {
			if(filterText.length > 0) { //add any filter by text input here
				let newer = [];
				let sboxval = filterText.toLowerCase();
				for (let r of rows) {
					for (let fld in r) {
						if(typeof(r[fld]) === 'string' && (isNaN(r[fld]) || parseFloat(r[fld]) != r[fld])) {
							if (forceKeepIds.includes(r.id) || r[fld].toLowerCase().indexOf(sboxval) > -1) {
								newer.push(r);
								break;
							}
						}
					}
				}
				rows = newer;
			}
			if(sortColName) {
				let useStrings = false;
				if(isNaN(rows[0][sortColName]) || parseFloat(rows[0][sortColName]) != rows[0][sortColName]) useStrings = true;
				if(sortColOrder === 'asc') {
					if(useStrings === true) {
						rows = rows.sort((a, b) => (a[sortColName].toLowerCase() > b[sortColName].toLowerCase() ? 1 : -1))
					}
					else rows = rows.sort((a, b) => (a[sortColName] > b[sortColName]) ? 1 : -1)
				}
				else {
					if(useStrings === true) rows = rows.sort((a, b) => (a[sortColName].toLowerCase() > b[sortColName].toLowerCase() ? -1 : 1))
					else rows = rows.sort((a, b) => (a[sortColName] > b[sortColName]) ? -1 : 1)
				}
			}
			return rows;
		},
		//end tables stuff

		/// old store functions
		showKalert(responseObject) {
			this.store.kalert.text = responseObject.message;
			if (responseObject.text !== "" && responseObject.text !== undefined) this.store.kalert.text = responseObject.text;
			if (responseObject.alerttype === "" || responseObject.alerttype === "error" || responseObject.alerttype === undefined) responseObject.alerttype = 'danger';
			if (responseObject.success === 1) responseObject.alerttype = 'success';
			this.store.kalert.type = responseObject.alerttype;
			if (responseObject.type !== "" && responseObject.type !== undefined) this.store.kalert.type = responseObject.type;
			this.store.kalert.shown = true;
			setTimeout(function() {
				this.store.kalert.shown = false;
			}.bind(this), 3000);
		},
		closeKalert() {
			this.store.kalert.shown = false;
		},
		setUser(userob) {
			this.store.user = userob;
		},
		closeAllMenus() {
			this.store.forceMenusClose++;
		},
		showTempDangerAlert(str, timeoutsec = 3) {
			let ob = { message: str, alerttype: 'error'	};
			this.showKalert(ob);
			setTimeout(function() {
				this.closeKalert();
			}.bind(this), timeoutsec*1000);
		},
		getExcelDataType(inval) {
			if(!isNaN(inval)) return 'n'
			return 's'
		},
	},
}